.folio__footer_container {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.folio__footer_text {
  font-family: var(--font-family);
  color: #bdbdbd;
}
