.folio__Features {
  justify-content: flex-end;
  align-items: center;
  border-radius: 15px;
}

.folio__Features_Body {
  padding: 0.5rem;
  margin: 1rem 1rem;
  outline: 1px solid #46464629;
}

.folio__Features_Body_Icon {
  padding-left: auto;
  padding-right: 4rem;
  margin-right: 0;
  position: absolute;
}

.folio__Features_Body_mainText p,
ul {
  padding: 0rem 1rem;
}

.folio__link {
  color: #00adb5;
}

.folio__Features_Title_Sub p {
  font-size: 18px;
  margin-top: 1rem;
  color: white;
}

.folio__certificate {
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.folio__caption-text_sub {
  margin-top: 2rem;
}

.folio__certificate-entry {
  margin-left: auto;
  margin-right: auto;
}

.folio__certificate-entry p {
  text-align: center;
}

#unity {
  width: 320px;
  margin-left: 5rem;
}

.folio__Features_Body_Image {
  align-content: center;
  justify-content: center;
  display: flex;
}
