.folio__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding-top: 5rem;
}

.folio__header_mainImg img {
  padding-right: 1rem;
  max-width: 100%;
}

.folio__header_text_containter {
  align-items: flex-start;
  display: block;
  margin-left: auto;
  margin-right: 2rem;
  margin-top: 2rem;
  padding-left: 2rem;
}

.folio__header_text_containter h1 {
  text-align: right;
}

.folio__header_text_main {
  font-size: 90px;
  display: block;
  margin-top: 1rem;
  font-weight: 300;
  color: #eeeeee;
}

.folio__header_text_sub {
  font-size: 40px;
  color: #bdbdbd;
  margin-top: 2rem;
  font-weight: 300;
}

.scale-down-center {
  -webkit-animation: scale-down-center 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-down-center 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:0:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 9:7:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: slide-in-right 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.slide-in-right-delay {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1s
    both;
  animation: slide-in-right 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 9:34:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.folio__header_image {
  position: fixed;
  margin-left: 22rem;
}

@media screen and (max-width: 800px) {
  .folio__header_text_main {
    font-size: 3rem;
  }

  .folio__header_text_sub {
    font-size: 1.3rem;
  }
}
