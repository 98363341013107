.folio__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background-color: #111111;
  position: fixed;

  padding: 0px 25px;
  padding-bottom: 10px;
  z-index: 1000;
  width: 1100px;
}

.folio__navbar * {
  background-color: #111111;
}

.folio__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.folio__navbar-links_logo {
  margin-right: 1rem;
}

.folio__navbar-links_logo p {
  font-weight: 900;
  font-size: 40px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.folio__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.folio__navbar-links_container p,
.folio__navbar-sign p,
.folio__navbar-menu_container p {
  color: #bdbdbd;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  text-transform: capitalize;

  margin: 1rem 1.2rem;
  cursor: pointer;
}

.folio__navbar-links_container2 p,
.folio__navbar-sign p,
.folio__navbar-menu_container p {
  color: #bdbdbd;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 25.6px;
  text-transform: capitalize;

  margin: 1rem 1.2rem;
  cursor: pointer;
}

.folio__navbar-contact button,
.folio__navbar-menu_container button {
  padding: 1rem 2rem;
  left: 1000px;
  top: 50px;
  color: #bdbdbd;
  background: none;
  border-radius: 5px;
  font-size: 20px;
  font-family: var(--font-family);
  border: 0;
  outline: none;
  cursor: pointer;
}

.folio__navbar-contact {
  right: 50px;
}

.media {
  color: whitesmoke;
  cursor: pointer;
  margin-left: 10px;
}

.folio__navbar-menu {
  margin-left: 3rem;

  display: none;
  position: static !important;
}

.folio__navbar-menu svg {
  cursor: pointer;
}

.folio__navbar-menu_container {
  display: static;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  top: 40px;
  position: absolute;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.folio__navbar-menu_container p {
  margin: 1rem 0;
}

@media screen and (max-width: 800px) {
  .folio__navbar-links_container {
    display: none;
  }
  .folio__navbar {
    padding: 0rem 1.2rem;
  }
}

@media screen and (max-width: 1100px) {
  .folio__navbar {
    width: 100%;
  }
}
