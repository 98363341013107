@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gugi&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --font-family: "Didact Gothic", sans-serif;
  --font-family3: "Gugi", cursive;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #111111;
  --color-footer: #3b3b3b;
  --color-blog: #111111;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
}
body {
  font-family: var(--font-family);
}
